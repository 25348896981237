.Login_container {
  width: 316px;
  margin: 0 auto;
  padding: 15px;
}

.Login_form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 5px;
}

.Login_logo {
  width: 256px;
  display: block;
  margin: auto;
  margin-bottom: 0px;
  z-index: 10;
}
