html, body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
  background-color: rgb(250 250 250);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  text-decoration: none;
}